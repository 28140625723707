<template>
  <div>
    <div class="flix-container flix-form-group">
      <div class="flix-row flix-form-group">
        <div class="flix-col-md-12 flix-text-center">
          <div class="flix-html-h1">
            <flixIcon :id="'unlock'" />
          </div>
          <h2 class="flix-html-h2">{{ $t('message.dashboard') }}</h2>
        </div>
        <div class="flix-col-md-4 flix-col-md-flix-offset-4">
          <div class="flix-form-group">
            <a
              href="#"
              @click.prevent="callback('loginForm')"
              class="flix-btn flix-btn-success flix-btn-block"
              ><flixIcon :id="'lock'" /> {{ $t('message.signIn') }}</a
            >
            <a
              href="#"
              @click.prevent="callback('registerForm')"
              class="flix-btn flix-btn-primary flix-btn-block"
              ><flixIcon :id="'plus'" /> {{ $t('message.signUp') }}</a
            >
          </div>
        </div>
        <div class="flix-col-md-4 flix-col-md-flix-offset-4">
          <div class="flix-form-group">
            <a
              href="#"
              @click.prevent="callback('passwordForm')"
              class="flix-html-a"
              ><flixIcon :id="'keys'" />
              {{ $tc('message.forgotPassword', 2) }}</a
            >
          </div>
          <div class="flix-form-group">
            <a
              href="#"
              @click.prevent="callback('ressourcesForm')"
              class="flix-html-a"
              ><flixIcon :id="'group'" />
              {{
                $t('message.edit', { name: $tc('message.ressources', 2) })
              }}</a
            >
          </div>
        </div>
      </div>
      <div class="flix-form-group">
        <div class="flix-text-center">
          <a
            :href="
              'https://bookingflix.' +
              ($i18n.locale === 'en' ? 'net' : $i18n.locale)
            "
            class="flix-html-h3 flix-html-a flix-text-danger flex flex-center flex-gap-5"
            ><flixIcon id="home"></flixIcon> {{ $t('message.toHomepage') }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    callback: Function
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {},
  computed: {}
}
</script>
<style lang="sass" scoped></style>
